import React, { Component } from 'react';
import Menu from './Menu.jsx';
class BasePage extends Component {
    constructor(props){
        super();
        this.props=props;
    }
  render() {    
    return (
            <div className={this.props.className} style={{height:"100%"}}>
    {this.props.children}
        </div>
    );
  }
}

export default BasePage;