import React, { Component} from 'react';
import Tools from '../Tools.jsx';
import logo from '../../img/logo.png';
class LogoEdupanel extends Component {
    constructor(props) {
        super();
        this.state = {};
        this.initFromProps(props);
    }
    initFromProps(props) {
        if (Tools.empty(props)) {
            return;
        }          
        //this.setState("onClick",props.onClick);
        this.state.onClick = props.onClick;
        this.state.backgroundColor = !Tools.empty(props) && !Tools.empty(props.backgroundColor) ? props.backgroundColor : "blue";
        this.state.label = !Tools.empty(props) && !Tools.empty(props.label) ? props.label : "";
    }
    render() {
        this.initFromProps(this.props);
        let props = this.state;
        let src=logo;
        return (
            <div style={{ backgroundImage: `url(${src})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
                backgroundPosition: 'center',width:"100%",height:"100%"}}>
            </div>
            
       );
    }
}

export default LogoEdupanel;