import React, { Component } from 'react';
//import BaseButton from '../../Base/cmps/BaseButton.jsx';
import Field from './Field.jsx';
import Tools from'../../../spxbasecode/Tools.jsx';
class Board09 extends Component {
    constructor(props) {
        super();
        this.props = props;
        this.selectedFields = [];
        this.notSelectedFields = [];
        this.players = [null, null];
        this.currentPlayer = this.players[0];
        this.fields = [];
        this.parent=props.parent;
        this.MAX_FIELDS = 9;
        this.MAX_IN_ROW = 3;
        this.MAX_IN_COL = 3;
        this.dems = [];
        this.rows = [];
        this.cols = [];
        this.parent.board=this;
        this.parent.runAfterload();
        
    }
    setPlayers(pls){
        this.players=pls;
        this.players[0].board=this;
        this.players[1].board=this;
        this.playerOwner=this.players[0];
        this.currentPlayer = this.players[0];
        
    }
    sendError(obj, error) {

    }
    
    pullFromNotSelectedFields(field) {
        let p = this.notSelectedFields.indexOf(field);
        this.notSelectedFields.splice(p, 1);
    }
    playerChange() {
        let p = this.players.indexOf(this.currentPlayer);
        this.currentPlayer=null;
        let n = this.players.length;
        let idx = (p + 1) % n;
        let this2=this;
        this.currentPlayer = this.players[idx];    
        this.parent.setCurrentPlayer(this.currentPlayer);
        if (typeof this2.currentPlayer.make_move==="function"){
            this2.currentPlayer.make_move();
        }
        
     
        
    }
    playerWIn(){
        window.App.changePage("Memtoe","memtoe_player_win",null);
        return;
    }
    playerLost(){
        window.App.changePage("Memtoe","memtoe_player_lost",null);
        return;
    }
    playerDraw(){
        window.App.changePage("Memtoe","memtoe_player_draw",null);
        return;
    }
    selectField(nr){
      let field=this.fields[nr-1];
      field.onSelect();
    }
    reactToFinishGame(player){
        if (this.is_player_win(player)){
            if (player===this.playerOwner){
                this.playerWIn();
                return true;
            }else{
                this.playerLost();
                return true;
            }
            
        }else if(this.is_draw()){
            this.playerDraw();
            return true;
        }
        return false;
    }
    onSelectField(field) {
        if(!this.reactToFinishGame(this.currentPlayer)){
               this.playerChange();
        }
    }
    render() {
        return (
                <div className={this.props.className} 
                style={{padding: "10px 10px 10px 10px", height: "100%"}}>
                    <div className='row' style={{height: "33%"}}>
                        <Field className="col-4" parent={this} nr="1" name="field_1" />
                        <Field className="col-4" parent={this} nr="2" name="field_2" />
                        <Field className="col-4" parent={this} nr="3" name="field_3" />
                    </div>
                    <div className='row' style={{height: "33%"}}>
                        <Field className="col-4" parent={this} nr="4" name="field_4" />
                        <Field className="col-4" parent={this} nr="5" name="field_5" />
                        <Field className="col-4" parent={this} nr="6" name="field_6" />
                    </div>
                    <div className='row' style={{height: "33%"}}>
                        <Field className="col-4" parent={this} nr="7" name="field_7" />
                        <Field className="col-4" parent={this} nr="8" name="field_8" />
                        <Field className="col-4" parent={this} nr="9" name="field_9" />
                    </div>
                </div>
                );
    }
    set_fields(fields) {
        this.fields = fields;
        for (let i = 0; i < this.MAX_FIELDS; i++) {
            let r = Math.floor(i / this.MAX_IN_ROW);
            let p = i % this.MAX_IN_ROW;
            if (Tools.empty(this.rows[r])) {
                this.rows[r] = [];
            }
            if (Tools.empty(this.cols[p])) {
                this.cols[p] = [];
            }
            this.rows[r][p] = this.fields[i];
            this.cols[p][r] = this.fields[i];
        }
        this.notSelectedFields =[];
        for(let k in this.fields){
            this.notSelectedFields[k]=this.fields[k];
        }
        this.dems[0] = [this.fields[0], this.fields[4], this.fields[8]];
        this.dems[1] = [this.fields[2], this.fields[4], this.fields[6]];
    }
    in_arr_win(player, arr) {
        let n = arr.length;
        let k = 0;
        for (let i = 0; i < n; i++) {
            let el = arr[i];
            if (!el.isSelected()) {
                return false;
            }
            if (el.player === player) {
                k++;
            }
        }
        return n === k;
    }
    is_rows_win(player) {
        let n = this.rows.length;
        for (let i = 0; i < n; i++) {
            let res = this.in_arr_win(player, this.rows[i]);
            if (res) {
                return true;
            }
        }
        return false;
    }
    is_dems_win(player) {
        let n = this.dems.length;
        for (let i = 0; i < n; i++) {
            let res = this.in_arr_win(player, this.dems[i]);
            if (res) {
                return true;
            }

        }
        return false;
    }
    is_cols_win(player) {
        let n = this.cols.length;
        for (let i = 0; i < n; i++) {
            let res = this.in_arr_win(player, this.cols[i]);
            if (res) {
                return true;
            }

        }
        return false;
    }

    is_draw(){
        return this.notSelectedFields.length===0;
               
    }
    is_player_win(player) {
        let is_win = false;
        is_win = this.is_rows_win(player);
        if (is_win) {
            return true;
        }
        is_win = this.is_cols_win(player);
        if (is_win) {
            return true;
        }
        is_win = this.is_dems_win(player);
        if (is_win) {
            return true;
        }

        return is_win;
    }
    make_move(nr, player, success, win, error) {
        let field = this.fields[nr - 1];
        field.check_by_player(player);
        if (this.is_player_win(player)) {
            win();
            return;
        }
        success();
    }
}

export default Board09;