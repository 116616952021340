import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import BaseSlimPage from '../../../spxbasecode/cmps/BaseSlimPage.jsx';
import PageWithMenu from '../../../spxbasecode/cmps/PageWithMenu.jsx';
import BaseButton from '../../../spxbasecode/cmps/BaseButton.jsx';
import Translate from '../../../Translate.jsx';

class MemtoeHomePage extends Component {
  constructor(){
    super();
    let this2=this;
    this.tag="MemtoeHomePage";
    this.module="Memtoe";
    window.App.readText(this.module,this.tag,[{tag:"text1"}]);
    setTimeout(function(){
    window.App.textToCommand.listen(this2,this2.tag);
    },500);
  }
  clickButton(pageName){
    this.openPage(pageName);
  }
    openPage(pageName){
        window.App.changePage("Memtoe",pageName,null);
    }
  render() {    
      let TT=Translate.getT(window.App.lang,"Memtoe",this.tag);
    return (
      <BaseSlimPage >
      <div className='row h-100 g-0 no-scroll'>
      <div className="row g-0 h-30"></div>
      <div className='row g-0 h-5'>
        <BaseButton label={TT.new_game} onClick={ (event) => this.openPage('memtoe_new_game')}></BaseButton>
      </div>
      <div className='row g-0 h-5'>
        <BaseButton label={TT.change_lang} onClick={(event) =>window.App.changeLangToNext()}></BaseButton>
      </div>
      <div className='row g-0 h-5'>
        <BaseButton label={TT.how_to_play} onClick={(event) =>this.openPage('memtoe_how_to_play')}></BaseButton>
      </div>
      <div className='row g-0 h-5'>
        <BaseButton label={TT.about_us}  onClick={(event) =>this.openPage('memtoe_about_us')}></BaseButton>
      </div>
            <div className="row g-0 h-30"></div>
      </div>
 </BaseSlimPage>
 

    );
    /*
<div className='row g-0 h-5'>
        <BaseButton label={TT.login} onClick={(event) =>this.openPage('memtoe_login')}></BaseButton>
      </div>
      <div className='row g-0 h-5'>
        <BaseButton label={TT.register} onClick={(event) =>this.openPage('memtoe_register')}></BaseButton>
      </div>

    */
  }
}

export default MemtoeHomePage;