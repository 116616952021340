import { SHA256 } from 'crypto-js';
import EduLog from './EduLog';
class Tools {

    static GetCurrentTimeStamp(){
        return  Date.now();
    }
    static copy(obj,isStatic=true){
        return Tools.empty(obj)?null:JSON.parse(JSON.stringify(obj));
    }
    static LSGet(key) {
        let val = localStorage.getItem(key);
        if (!Tools.isset(val)) {
            return null;
        }
        return JSON.parse(val);
    }
    static alert(mess){
        alert(mess);
    }
    static LSSet(key, val) {
        let v = JSON.stringify(val);
        localStorage.setItem(key, v);

    }
    
    static GetLang(){
        var lang = navigator.language || navigator.userLanguage;
        let MAP={"en-US":"en_GB","pl-PL":"pl_PL"};
        if (!Tools.empty(lang)&&!Tools.empty(MAP[lang])){
            return MAP[lang];
        }
        return 'en_GB';
    }
    static isset(obj) {
        return !(typeof obj === "undefined" || obj === null);
    }
    static log(mm, mm2) {
        console.log("log", mm, mm2);
        let xx = JSON.stringify(mm);
        alert(xx);
    }
    static DeviceIdGen() {
        let obj = {};
        obj.app = window.navigator.userAgent;
        obj.rand = crypto.randomUUID();
        let hash = Tools.SHA1(JSON.stringify(obj));
        Tools.LSSet("DEVICE_ID", hash);
        return hash;
    }
    static async GetDeviceId() {
        let v = Tools.LSGet("DEVICE_ID");
        if (Tools.empty(v)) {
            let h = Tools.DeviceIdGen();
            return h;
        }
        return v;
    }
    static SHA1(str) {
        return SHA256(str).toString();

    }
    static IsFunction(obj){
        return typeof obj==='function';
    }
    static IsObject(obj){
        return typeof obj==='object';
    }
    static IsArray(obj){
        if (!Tools.IsObject(obj)){
            return false;
        }
        return obj instanceof Array;
    }
    static arrayToStringSQL(arr) {
        let str = "'" + arr.join("', '") + "'";
        return str;
    }
    static firtLeterUpper(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    static firstIndexOfText(ff, text) {
        return text.indexOf(ff);
    }
    static empty(obj) {

        if (!Tools.isset(obj)) {
            return true;
        }
        if (obj === undefined || typeof obj === 'undefined' ||
            obj === null ||
            obj === "null" ||
            obj === "" || obj === "[]") {
            return true;
        }
        if (obj.length === 0) {
            return true;
        }
        return false;

    }
    static SendToApi(url, data, success, error) {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', url, true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
                let text = xhr.responseText;
                //console.log(text);
                let resp=null;
                try{
                resp = JSON.parse(text);
                success(resp);
                }catch(e){
                  //  EduLog.AddLog("Tools::SendToApi",{"url":url,"data":data,"resp":resp});
                }
            } else if (xhr.readyState === 4 && xhr.status !== 200) {
                // Handle error
                error(xhr.responseText);
            }
        }; 
        xhr.send(JSON.stringify(data));
    }
}
export default Tools;