import React, { Component } from 'react';
import PlayerInfo from './PlayerInfo.jsx';

//import Translate from '../../../Translate.jsx';

class PlayerAI extends Component {
  constructor(props){
    super();
    this.typeClass="PlayerAI";
    this.props = props;
    // this.name = props.name;
     this.symbol = props.symbol;
     this.states = {};
     this.states.className = this.props.className;
     this.playerInfo={fullName:this.props.fullName};
     this.backgroundColor = props.color;
     this.awatar=props.awatar;
     this.parent = props.parent;
     this.parent.players[props.pos] = this;
     this.info = null;
     this.cssPlayer=this.parent.cssPlayer;
    if (props.pos==="1"){
      this.parent.setPlayers();
    }
}

random_move(){
  let ff=this.board.notSelectedFields;
   let n=ff.length;
   let nr=Math.floor(Math.random()*n);
   let field=ff[nr];
   return field;
}
hide(){
  this.info.hide();
  this.states.className="hiddenComponent";
  this.forceUpdate();
 
}
show(){
  this.info.show();
  this.states.className=this.cssPlayer;
  this.forceUpdate();
}
make_move(){
   let field=this.random_move();
   setTimeout(() => {
    field.onSelect(); 
   }, 1000);
   
}
    getBackgroundColor(){

      return this.backgroundColor;
    }
  render() {    
    return (
      <div className={this.states.className}>
        <PlayerInfo  awatar={this.awatar} className="row h-100" info={this.playerInfo} parent={this} />
      </div>
    );
  }
}

export default PlayerAI;