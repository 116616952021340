import React, { Component } from 'react';
import Translate from '../../../Translate.jsx';
import Tools from '../../../spxbasecode/Tools.jsx';
import BaseCard from '../../../spxbasecode/cmps/BaseCard.jsx';
import imgWin from '../../../img/win.png';
import BaseButton from '../../../spxbasecode/cmps/BaseButton.jsx';
import imgDraw from '../../../img/draw.png';
import imgLost from '../../../img/lost.png';

class GameFinish extends Component {
  constructor(props) {
    super();
    this.props = props;
    this.img=null;
    this.pageName=null;
    let status=!Tools.empty(props.status)?props.status:"";
    switch (status) {
      case "WIN":this.img=imgWin;this.pageName="MemtoePlayerWinPage";break;
      case "LOST":this.img=imgLost;this.pageName="MemtoePlayerLostPage";break;
      case "DRAW":this.img=imgDraw;this.pageName="MemtoePlayerDrawPage";break;
      default:
        break;
    }
   
  }
  openPage(pageName){
    window.App.changePage("Memtoe",pageName,null);
}
  render() {
     let TT=Translate.getT(window.App.lang,"Memtoe",this.pageName);
     let TT2=Translate.getT(window.App.lang,"Memtoe","GameFinish");
    let img1=this.img;  
    return (
      <div style={{height:"100%",width:"100%"}}>
        <div style={{height:"70%",width:"100%"}}>
          <BaseCard backgroundColor={window.App.Config.backgroundColor} title={TT.title} text={TT.text} src={img1} />
          </div>
          <div style={{height:"15%",width:"100%"}}>
          <BaseButton label={TT2.new_game} onClick={ (event) => this.openPage('memtoe_new_game')}/>
          </div>
          <div style={{height:"15%",width:"100%"}}>
          <BaseButton label={TT2.back} onClick={ (event) => this.openPage('home')}/>
          </div>
      </div>
    );
  }
}

export default GameFinish;
