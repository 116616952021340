import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import Tools from '../Tools.jsx';


class BaseAnimation extends Component {
  constructor(props) {
    super(props);
    this.props=props;
    this.stopAnim=false;
    this.state = {
      currentComponentIndex: 0
    };
  }

  componentDidMount() {
    const { components } = this.props;
    let time=components[0].time;
    this.interval = setInterval(this.changeComponent, time);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  changeComponent = () => {
    const { currentComponentIndex } = this.state;
    const { components } = this.props;
    const newIndex = (currentComponentIndex + 1) % components.length;
    if (newIndex==0 & Tools.IsFunction(this.props.onEnd)){
      clearInterval(this.interval);
      this.props.onEnd();
      return;
    }
    this.setState({ currentComponentIndex: newIndex });
  };

  render() {
    const { currentComponentIndex } = this.state;
    const { components,className } = this.props;
    const { component: CurrentComponent, time, data } = components[currentComponentIndex];
    return (
      <div style={{textAlign:"center"}} className={className}>
        <CSSTransition
         
          in={true}
          appear={true}
          timeout={time}
          classNames="fade"
        >
          <CurrentComponent data={data}/>
        </CSSTransition>
      </div>
    );
  }
}

export default BaseAnimation;
