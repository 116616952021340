import React, { Component } from 'react';
import Translate from '../../../Translate.jsx';
import PlayerInfo from './PlayerInfo.jsx';

class Player extends Component {
  constructor(props) {
    super();
    this.typeClass="Player";
    this.props = props;
   // this.name = props.name;
    this.symbol = props.symbol;
    this.states = {};
    this.states.className = this.props.className;
    this.playerInfo={fullName:this.props.fullName};
    this.backgroundColor = props.color;
    this.awatar=props.awatar;
    this.parent = props.parent;
    this.parent.players[props.pos] = this;
    this.info = null;
    this.cssPlayer=this.parent.cssPlayer;
  }
  hide() {
    this.info.hide();
    this.states.className = "hiddenComponent";
    this.forceUpdate();

  }

  show() {
    this.info.show();
    this.states.className = this.props.className;
    this.forceUpdate();
  }

  getBackgroundColor() {

    return this.backgroundColor;
  }
  render() {
    // let TT=Translate.getT(window.App.lang,"Memtoe","HomePage");
    return (
      <div className={this.states.className}>
        <PlayerInfo awatar={this.awatar} className="row h-100" info={this.playerInfo} parent={this} />
      </div>
    );
  }
}

export default Player;