import { Component } from 'react';
class TCT_memtoe_En_GB extends Component {
    static map = {
        MemtoeHomePage: {
            "new game": {
                "fun": "clickButton",
                "param": "memtoe_new_game"
            },
            "how to play": {
                "fun": "clickButton",
                "param": "memtoe_how_to_play"
            },
            "about us": {
                "fun": "clickButton",
                "param": "memtoe_about_us"
            }
        },
        MemtoeNewGamePage: {
            "select": {
                "fun": "selectField",
                "commands": {
                    "1": "1",
                    "2": "2",
                    "3": "3",
                    "4": "4",
                    "5": "5",
                    "6": "6",
                    "7": "7",
                    "8": "8",
                    "9": "9",
                },
            }
        },
        default:{
            "back": {
                "fun": "clickButton",
                "param": "back"
            },
        }
    };

    
}
export default TCT_memtoe_En_GB;