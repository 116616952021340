import React, { Component } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import BaseLang from './BaseLang.jsx';
import BaseReadTextButton from './BaseReadTextButton.jsx';
//import BaseSSOLogin from './BaseSSOLogin.jsx';

import logo from '../../img/logo.png';
class Menu extends Component {
  constructor(props) {
    super();
    this.props = props;
    window.App.Config.isMenuLeave = !this.props.isShow;// && !window.App.Config.isMenuLeave;
        
  }
  handleMouseMove() {
    window.App.Config.isMenuLeave = false;
    this.forceUpdate();
  }

  handleMouseLeave() {
    window.App.Config.isMenuLeave = true;
    this.forceUpdate();
  }

  render() {

    let MAP = window.App.Config.menu;
    let dis = "flex";
    let displayAudioButt="flex";
    let op=1;
    let pos="absolute";
    if (!window.App.Config.isPlayAudio){
      displayAudioButt="none";
    }
    if (!window.App.Config.isMenuShow){
        dis="none";
    }
    if (window.App.Config.isMenuLeave){
      op=0;
      pos="absolute";
    }
    return (
      <Navbar onMouseMove={(event) => this.handleMouseMove()}
        onMouseLeave={(event) => this.handleMouseLeave()} 
        style={{ display: dis,opacity:op,position:pos,zIndex:10000 }} collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Navbar.Brand href="/">
          <img width="60" height="60" src={logo} alt="Logo" /></Navbar.Brand>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            {MAP.map((item, index) => (
              <Nav.Link key={index} onClick={(event) => item.action()}>{item.title}</Nav.Link>
            ))}
          </Nav>
        </Navbar.Collapse>
        <div style={{ marginLeft: "auto", marginRight: "5px" }}>
          <table><tbody><tr>
            <td style={{display: displayAudioButt}}><BaseReadTextButton  width="40px" height="40px" /></td>
            <td><BaseLang /></td>
          </tr>
          </tbody>
          </table>
        </div>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{ backgrandColor: "red" }} />

      </Navbar>
    );

  }
  render2() {
    return (
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Navbar.Brand href="/">Edupanel.pl</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="#features">Features</Nav.Link>
            <Nav.Link href="#pricing">Pricing</Nav.Link>
            <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav>
            <Nav.Link href="#deets">More deets</Nav.Link>
            <Nav.Link eventKey={2} href="#memes">
              Dank memes
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }

}

export default Menu;