import React, { Component } from 'react';
import SpeechToText from './SpeechToText.jsx';
import Tools from '../Tools.jsx';
class TextToCommand extends Component {
    constructor(props) {
        super();
        this.props=props;
        this.speech=null;
        this.props.parent.textToCommand=this;
        this.interval=5000;
        this.props.parent.TTCInit();
    }
    listen(parent, pageName) {
       this.speech.handleListen();
        this.parent = parent;
        this.pageName = pageName;
        let this2=this;
        setInterval(function(){
            let cmd=this2.speech.getTranscript();
            this2.command(cmd);
        },this.interval);
    }
    command(text) {
        this.speech.reset();
        let pageName = this.pageName;
        /*this.MAP = {
            "home": {
                "naciśnij przycisk": {
                    "fun": "clickButton",
                    "commands": {
                        "nowa gra": "new_game",
                        "o nas": "about_us",
                        "pomoc": "help"
                    },
                }
            },"new_game": {
                "zaznacz pole": {
                    "fun": "selectField",
                    "commands": {
                        "numer 1": "1",
                        "numer 2": "2",
                        "numer 3": "3",
                        "numer 4": "4",
                        "numer 5": "5",
                        "numer 6": "6",
                        "numer 7": "7",
                        "numer 8": "8",
                        "numer 9": "9",
                    },
                }
            },
        };*/
        let map = this.MAP[window.App.lang][pageName];
        if (!Tools.isset(map)){
            map = this.MAP[window.App.lang].default;
        }
        for (let k in map) {
            let i = text.indexOf(k);
            if (i === -1) { continue; }
            let coms = map[k].commands;
            if (!Tools.isset(coms)){
                let fun = map[k].fun;
                let pp =  map[k].param;
                
                this.parent[fun](pp);
                this.speech.reset();
                return;
            }
            for (let l in coms) {
                let j = text.indexOf(l);
                if (j === -1) { continue; }
                let fun = map[k].fun;
                let pp = coms[l];
                //console.log("ffff",fun,pp,this.parent);
                this.parent[fun](pp);
                this.speech.reset();
            }
        }

    }
    render() {
        return (<div><SpeechToText parent={this}></SpeechToText></div>
        );
    }
}

export default TextToCommand;