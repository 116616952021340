import React, { Component } from 'react';
import BaseSlimPage from '../../../spxbasecode/cmps/BaseSlimPage.jsx';
import Game09 from '../../Memtoe/cmp/Game09.jsx';

class MemtoeNewGamePage extends Component {
  constructor(){
    super();
    this.game=null;
    this.tag="MemtoeNewGamePage";
    this.module="Memtoe";
    window.App.readText(this.module,this.tag,[{tag:"text1"}]);
    

  }
  runAfterload(){
    window.App.textToCommand.listen(this.game.board,this.tag);
  }
  render() {    
    return (
      <BaseSlimPage  isMenuShow={false}>
      <div className='row h-100 g-0 no-scroll'>
        <Game09 parent={this}/>
      </div>
 </BaseSlimPage>
    );
  }
}

export default MemtoeNewGamePage;