import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import Tools from '../Tools.jsx';
class BaseButton extends Component {
    constructor(props) {
        super();
        this.state = {};
        this.initFromProps(props);
    }
    initFromProps(props) {
        if (Tools.empty(props)) {
            return;
        }
        //this.setState("onClick",props.onClick);
        this.state.onClick = props.onClick;
        this.state.backgroundColor = !Tools.empty(props) && !Tools.empty(props.backgroundColor) ? props.backgroundColor : "blue";
        this.state.label = !Tools.empty(props) && !Tools.empty(props.label) ? props.label : "";
    }
    render() {
        this.initFromProps(this.props);
        let props = this.state;
        return (
            <Button style={{ width: "100%", height: "100%", backgroundColor: props.backgroundColor }} onClick={(event) => props.onClick()} className="font-weight-bold">
                {props.label}
            </Button>
        );
    }
}

export default BaseButton;