import React, { Component } from 'react';
import Menu from './Menu.jsx';
class PageWithMenu extends Component {
    constructor(props){
        super();
        this.props=props;
    }
  render() {    
    return (
      <div style={{backgroundColor:"black"}}>
            <Menu></Menu>
            <div className={this.props.className} style={{height:"100%"}}>
    {this.props.children}
        </div>
        </div>
    );
  }
}

export default PageWithMenu;