import React, { Component } from 'react';
import {ReactComponent as LangPl} from '../../img/lang_pl.svg';
import {ReactComponent as LangEn} from '../../img/lang_en.svg';

function BaseFlag(props) {
    if (props.lang === "pl_PL") {
        return (<LangPl width={props.width} height={props.height}/>);
    } else
    if (props.lang === "en_GB") {
      return (<LangEn width={props.width} height={props.height}/>);
    } 
    else {
      return (<LangEn width={props.width} height={props.height}/>);
    }
}

class BaseLang extends Component {
  constructor(props) {
    super();
    this.props = props;
  }
  render() {
    let lang="pl_PL";
    if(window.App.lang==='pl_PL'){
        lang="en_GB";
    }
    return (<div onClick={(event) => window.App.changeLang(lang)}  width="50" height="50">
      <BaseFlag  width="50" 
      height="50" lang={lang} />
    </div>
    );
  }


}

export default BaseLang;