import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import BaseSlimPage from '../../../spxbasecode/cmps/BaseSlimPage.jsx';
import Translate from '../../../Translate.jsx';
import BaseCard from '../../../spxbasecode/cmps/BaseCard.jsx';
import BaseButton from '../../../spxbasecode/cmps/BaseButton.jsx';

import img1 from '../../../img/logo.png';

class MemtoeHowToPlayPage extends Component {
    constructor(){
    super();
    let this2=this;
    this.tag="MemtoeHowToPlayPage";
    this.module="Memtoe";
    window.App.readText(this.module,this.tag,[{tag:"text1"}]);
    
    setTimeout(function(){
    window.App.textToCommand.listen(this2,this2.tag);
    },500);
  }
  back(){
    window.App.goBack();
  }
  render() {    
    let TT2=Translate.getT(window.App.lang,"Memtoe","GameFinish");
  
   let TT = Translate.getT(window.App.lang, this.module, this.tag);
    return (
      <BaseSlimPage>      
        <Container className="my-4">
          <Row>
            <Col md={12}>
              <BaseCard backgroundColor={window.App.Config.backgroundColor} title={TT.title} text={TT.text} src={img1} />
            </Col>
          </Row>
          <div style={{height:"15%",width:"100%"}}>
          <BaseButton label={TT2.back} onClick={ (event) => this.back()}/>
          </div>
        </Container>
      </BaseSlimPage>
    );
  }
}

export default MemtoeHowToPlayPage;