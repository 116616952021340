import React, { Component } from 'react';
import './App.css';
import TextToCommand from './spxbasecode/cmps/TextToCommand';
import API from './spxbasecode/API.jsx';
import Tools from './spxbasecode/Tools.jsx';
import MemtoeLogoPage from './Module/Memtoe/Page/MemtoeLogoPage';
import MemtoeHomePage from './Module/Memtoe/Page/MemtoeHomePage';
import MemtoeHowToPlayPage from './Module/Memtoe/Page/MemtoeHowToPlayPage';
import MemtoeNewGamePage from './Module/Memtoe/Page/MemtoeNewGamePage';
import MemtoeAboutUsPage from './Module/Memtoe/Page/MemtoeAboutUsPage';
import MemtoePlayerWinPage from './Module/Memtoe/Page/MemtoePlayerWinPage';
import MemtoePlayerLostPage from './Module/Memtoe/Page/MemtoePlayerLostPage';
import MemtoePlayerDrawPage from './Module/Memtoe/Page/MemtoePlayerDrawPage';
import SSOLoginPage from './Module/SSO/Page/SSOLoginPage';
import Translate from './Translate';
import NoPage from './Module/Memtoe/Page/NoPage';
import AdvPage from './Module/Memtoe/Page/AdvPage';
import FundPage from './Module/Memtoe/Page/FundPage';
import NoInternetPage from './Module/Memtoe/Page/NoInternetPage';
import UpdatePage from './Module/Memtoe/Page/UpdatePage';
import NoSoundPage from './Module/Memtoe/Page/NoSoundPage';
import { createBrowserHistory } from 'history';
import TextToRead from './TextToRead';
import TCT_memtoe_Pl_PL from './Module/Memtoe/TCT/TCT_Pl_PL';
import TCT_memtoe_En_GB from './Module/Memtoe/TCT/TCT_En_GB';
import { FaTruckLoading } from 'react-icons/fa';



function DynamicTag(props) {
    if (props.tag === "MemtoeHomePage") {
        return (<MemtoeHomePage></MemtoeHomePage>);
    } else if (props.tag === "MemtoeLogoPage") {
        return (<MemtoeLogoPage></MemtoeLogoPage>);
    }
    else if (props.tag === "MemtoeHowToPlayPage") {
        return (<MemtoeHowToPlayPage></MemtoeHowToPlayPage>);
    }
    else if (props.tag === "MemtoeNewGamePage") {
        return (<MemtoeNewGamePage></MemtoeNewGamePage>);
    }
    else if (props.tag === "MemtoeAboutUsPage") {
        return (<MemtoeAboutUsPage></MemtoeAboutUsPage>);
    }
    else if (props.tag === "MemtoePlayerWinPage") {
        return (<MemtoePlayerWinPage></MemtoePlayerWinPage>);
    }
    else if (props.tag === "MemtoePlayerLostPage") {
        return (<MemtoePlayerLostPage></MemtoePlayerLostPage>);
    }
    else if (props.tag === "MemtoePlayerDrawPage") {
        return (<MemtoePlayerDrawPage></MemtoePlayerDrawPage>);
    }
    else if (props.tag === "NoSoundPage") {
        return (<NoSoundPage></NoSoundPage>);
    }
    else if (props.tag === "SSOLoginPage") {
        return (<SSOLoginPage />);
    }
    else if (props.tag === "AdvPage") {
        return (<AdvPage />);
    }
    else if (props.tag === "FundPage") {
        return (<FundPage />);
    }
    else if (props.tag === "NoInternetPage") {
        return (<NoInternetPage />);
    }
    else if (props.tag === "UpdatePage") {
        return (<UpdatePage />);
    }
    else {
        return (<NoPage></NoPage>);
    }
}

class App extends Component {
    PAGES_MAP = {
        ads:"AdvPage",
        fund:"FundPage",
        no_intenet:"NoInternetPage",
        update:"UpdatePage",
        "": "MemtoeLogoPage",
        logo: "MemtoeLogoPage",
        nosound: "NoSoundPage",
        home: "MemtoeHomePage",
        memtoe_new_game: "MemtoeNewGamePage",
        memtoe_about_us: "MemtoeAboutUsPage",
        memtoe_player_win: "MemtoePlayerWinPage",
        memtoe_player_lost: "MemtoePlayerLostPage",
        memtoe_player_draw: "MemtoePlayerDrawPage",
        memtoe_how_to_play: "MemtoeHowToPlayPage",
        login:"SSOLoginPage"

    };
    constructor() {
        super();
        Tools.GetDeviceId().then(id => {
            this.DeviceID = id;
        });
        this.langs=["pl_PL","en_GB"];
        window.App = this;
        this.textToCommand = null;
        this.lang = Tools.GetLang();
       // this.lang = "pl_PL";
        this.history = createBrowserHistory();
        let TT=Translate.getT(window.App.lang,"Memtoe","MemtoeHomePage");
        this.historyBrows=[];
        this.Config={backgroundColor:"black"
        ,ver:"1.0"
        ,isMenuShow:false
            ,menu:[{ title: TT.login, action: (event) =>window.App.changePage(null,"login") },
            { title: TT.register, action: (event) =>window.App.changePage(null,"regiser") },
            { title: TT.logout, action: (event) =>window.App.changePage(null,"logout") }
        ]
        };
        window.App.Config.isMenuLeave = false;
        window.App.Config.isPlayAudio=false;
        this.tag = "MemtoeLogoPage";
        //this.tag = "FundPage";
       // this.tag = "SSOLoginPage";
        this.isBlind = true;
        //this.isInteract=false;
        this.handleBackButton();
        let this2 = this;
        this.API = new API();
        
        this.API.exec("sso","getVer",null,(ss)=>{
            let xx=!Tools.empty(ss.data)&&!Tools.empty(ss.data.ver)?parseFloat(ss.data.ver):null;
            let ver=parseFloat(this2.Config.ver);
          
            if (Tools.empty(xx)){
                window.App.changePage(null,"no_internet");
                return;
            }else if (xx>ver){
                window.App.changePage(null,"update");
                return;
            }
            
           
        },(ee)=>{
            window.App.changePage(null,"no_internet");
        });
    }

    handleBackButton() {
        if (window.history && window.history.pushState) {
            let this2 = this;
            window.addEventListener('popstate', function () {
                let pageName = window.location.pathname.substring(1);
                this2.changePage(null, pageName);
            });
        }
    }
    TTCInit() {
        this.textToCommand.MAP = {
            pl_PL: TCT_memtoe_Pl_PL.map,
            en_GB: TCT_memtoe_En_GB.map
        };
    }
    tryPlay() {
        this.play();
    }
    play() {
        this.textToRead.isInteract = true;
        this.textToRead.play();
    }
    readText(modle, pageName, tag) {
        let this2 = this;
        setTimeout(() => {
            this2.textToRead.read(modle, pageName, tag);
            this2.tryPlay();
        }, 150);

    }
    render() {

        const customTag = this.tag;
        return (
            <div style={{ height: "100%", width: "100%"}}>
                <div className='row g-0' style={{ height: "100%" }}>
                    <DynamicTag tag={customTag} />
                </div>
                <div><TextToCommand parent={this}></TextToCommand>
                    <TextToRead parent={this}></TextToRead>
                </div>
            </div>
        );

    }
    getTag(pageName) {
        return this.PAGES_MAP[pageName];
    }
    goBack(){
        let n=this.historyBrows.length;
        let k=n-2;
        if (k<0){
            k=0;
        }
        let p=this.historyBrows[k];
        this.changePage(p.module,p.pageName);
    }
    goAds(module,pageName,params){
        if (["ads"].indexOf(pageName)>=0){
            return false;
        }
        let zz=["AdvPage"].indexOf(this.tag);
        if (zz>=0){
            return false;
        } 
        let k=["memtoe_new_game"].indexOf(pageName);
        
        if (!(k>=0)){
            return false;
        }
        window.App.goNextPageInfo={module:module,pageName:pageName,params:params};
        this.changePage(null,"ads",params);
        return true;
    }
    goFund(module,pageName,params){
        if (["fund"].indexOf(pageName)>=0){
            return false;
        }
        let zz=["FundPage"].indexOf(this.tag);
        if (zz>=0){
            return false;
        } 
        let k=["memtoe_new_game"].indexOf(pageName);
        
        if (!(k>=0)){
            return false;
        }
        window.App.goNextPageInfo={module:module,pageName:pageName,params:params};
        this.changePage(null,"fund",params);
        return true;
    }
   
    changePage(module, pageName, params) {
        /*let xx=this.goAds(module,pageName, params);
        if (xx){
            return;
        }*/
        let xx=this.goFund(module,pageName, params);
        if (xx){
            return;
        }
        let tag = this.getTag(pageName);
        this.tag = tag;
        let path = "/" + pageName;
       // console.log("zzzz",pageName,tag);
        this.historyBrows.push({module:module,pageName:pageName});
        //this.history.push(path);
        this.forceUpdate();
    }
    changeLangToNext(){
        let i=this.langs.indexOf(this.lang);
        let n=this.langs.length;
        let k=(i+1)%n;
        this.lang=this.langs[k];
        this.changeLang(this.lang);    
    }
    changeLang(lang) {
        this.lang = lang;
        this.forceUpdate();
    }
}

export default App;
