import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import Tools from '../Tools.jsx';
import BaseImg from './BaseImg.jsx';
class BaseButtonImage extends Component {
    constructor(props) {
        super();
        this.states = {};
        this.initFromProps(props);
    }
    initFromProps(props) {
        if (Tools.empty(props)) {
            return;
        }
        //this.setState("onClick",props.onClick);
        this.states.onClick = props.onClick;
        this.states.backgroundColor = !Tools.empty(props) && !Tools.empty(props.backgroundColor) ? props.backgroundColor : "inherit";
        this.states.label = !Tools.empty(props) && !Tools.empty(props.label) ? props.label : "";
        this.states.width = !Tools.empty(props) && !Tools.empty(props.width) ? props.width :"250px";
        this.states.height = !Tools.empty(props) && !Tools.empty(props.height) ? props.height :"50px";
        this.states.photo = !Tools.empty(props) && !Tools.empty(props.photo) ? props.photo :null;
        
        
    }
    render() {
        this.initFromProps(this.props);
        let props = this.states;

        /*
        <Button style={{ width: "100%", height: "100%", 
                backgroundColor: props.backgroundColor }} 
                onClick={(event) => props.onClick()} className="font-weight-bold">
                {props.label}
            </Button>
        */

            /*
<div style={{ width:props.width, height: props.height, 
            backgroundColor: props.backgroundColor }} 
            onClick={(event) => props.onClick()} className="font-weight-bold"
            ></div>

            */
        //    ,backgroundImage:props.photo
        return (

            <div style={{ width:props.width, height:props.height, 
            backgroundColor: props.backgroundColor }} 
            onClick={(event) => props.onClick()} className="font-weight-bold"
            >
                <BaseImg photo={props.photo} width={props.width} height={props.height}>

                </BaseImg>
            </div>
        );
    }
}

export default BaseButtonImage;