import React, { Component } from 'react';
//import BaseButton from '../../Base/cmps/BaseButton.jsx';
//import Translate from '../../../Translate.jsx';
import Board09 from './Board09.jsx';
import GameInfo from './GameInfo.jsx';
class Game09 extends Component {
  constructor(props) {
    super();
    this.props = props;
    this.parent = props.parent;
    this.board = props.parent;
    this.parent.game = this;
  }
  setCurrentPlayer(player){
    this.gameInfo.setCurrentPlayer(player);
  }
  setPlayers(pls) {
    let this2 = this;
    setTimeout(function () {
      this2.board.setPlayers(pls);
    }, 500);
  }
  runAfterload() {
    this.parent.runAfterload();
  }
  render() {
    //   let TT=Translate.getT(window.App.lang,"Memtoe","HomePage");
    return (
      <div className={this.props.className}>
        <GameInfo parent={this} className="row h-15 " />
        <Board09 className="row h-85" parent={this} ></Board09>
      </div>
    );
  }
}

export default Game09;