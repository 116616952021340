import React, { Component } from 'react';
import { Form, FormGroup, Label, Input, Button, Alert } from 'reactstrap';
import Tools from '../Tools.jsx';
import Menu from './Menu.jsx';
import Validators from '../Validators.jsx';
class BaseInputString extends Component {
    constructor(props){
        super();
        this.props=props;
        this.value="";
        this.state={
          value:props.value
        };
        this.states={
          id:props.id,
          label:props.label,
          name:props.name,
          placeholder:props.placeholder,
          validator:{
            maxLength:props.maxlength,
            minLength:props.minlength,
            require:props.require
          }

        };
        this.validator=this.states.validator;
        let this2=this;
        setTimeout(()=>{

        this2.props.parent.form.addObj(this2);
        },500);
        this.states.type="text";
    }
    setNeutral(){
      this.className="inputStringNeutral";
    }
    setOK(){
      this.className="inputStringOK";
    }
    setError(){

    }
    async valid(){
      this.setNeutral();
      let val=this.getValue();
      let val1= await Validators.IsRequire(this,val);
      let val2= await Validators.HasMinLength(this,val);
      let val3= await Validators.HasMaxLength(this,val);
      return val1&&val2&&val3;
    }
    getValue(){
      return this.value;
    }
    handleChange = event => {
      this.setNeutral();
      const { name, value } = event.target;
      console.log(value);
      this.value=Tools.copy(value,true);
      this.states.value=Tools.copy(value,true);
    }
  render() {    
    return (
      <FormGroup>
      <Label style={{color:'white'}} for="email">{this.states.label}</Label>
      <Input className={this.className} type={this.states.type} name={this.states.name} id={this.states.id} 
      placeholder={this.states.placeholder} value={this.state.value} 
      onChange={this.handleChange} />
    </FormGroup>
           
    );
  }
}

export default BaseInputString;