import React, { Component } from 'react';
import { Form, FormGroup, Label, Input, Button, Alert } from 'reactstrap';
import BaseForm from '../../../spxbasecode/cmps/BaseForm';
import Translate from '../../../Translate';
import BaseInputString from '../../../spxbasecode/cmps/BaseInputString';
class SSOLoginForm extends Component {
  constructor(props) {
    super(props);
    this.tag="SSOLoginForm";
    this.state = {
      login: '',
      password: '',
      error: null
    };
    this.form={vals:{login:""}};
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleSubmit = event => {
    event.preventDefault();
    
   
  }

  render() {
    let TT=Translate.getT(window.App.lang,"SSO",this.tag);
    return (
      <BaseForm className="row" parent={this}>
        <BaseInputString name="login" id="login" label={TT.login.label} placeholder={TT.login.placeholder} 
        parent={this} require="true" minlength="5" maxlength="20"/>
        <BaseInputString type="password" name="passwd" id="passwd" label={TT.passwd.label} placeholder={TT.passwd.placeholder} 
        parent={this} require="true" minlength="5" maxlength="20"/>
    
      </BaseForm>
    );
   /* const { login, password, error } = this.state;
    return (
      <Form onSubmit={this.handleSubmit}>
        {error && <Alert color="danger">{error}</Alert>}
        <FormGroup>
          <Label for="email">Login</Label>
          <Input type="text" name="login" id="login" placeholder="Login" value={login} onChange={this.handleChange} />
        </FormGroup>
        <FormGroup>
          <Label for="password">Password</Label>
          <Input type="password" name="password" id="password" placeholder="Password" value={password} onChange={this.handleChange} />
        </FormGroup>
        <Button color="primary" type="submit">Login</Button>
      </Form>
    );
    */
  }
}

export default SSOLoginForm;