import React from 'react';
import PropTypes from 'prop-types';
import Tools from '../Tools.jsx';

class BaseLabel extends React.Component {
  render() {
    let { text, color, font, size,data } = this.props;
    if (!Tools.empty(data)){
        color=data.color;
        font=data.font;
        size=data.font_size;
        text=data.text;

    }
    const labelStyle = {
      color: color,
      fontFamily: font,
      fontSize: size,
      //textAlign:"center"
    };

    return <label style={labelStyle}>{text}</label>;
  }
}

BaseLabel.propTypes = {
  content: PropTypes.string,
  color: PropTypes.string,
  font: PropTypes.string,
  size: PropTypes.string,
};

BaseLabel.defaultProps = {
  color: 'black',
  font: 'Arial',
  size: '14px',
};

export default BaseLabel;