import React, { Component} from 'react';
import Tools from '../Tools.jsx';
class BaseImg extends Component {
    constructor(props) {
        super();
        this.states = {};
        this.initFromProps(props);
    }
    initFromProps(props) {
        if (Tools.empty(props)) {
            return;
        }          
        //this.setState("onClick",props.onClick);
        this.states.onClick = props.onClick;
        this.states.backgroundColor = !Tools.empty(props) && !Tools.empty(props.backgroundColor) ? props.backgroundColor : "inherit";
        this.states.label = !Tools.empty(props) && !Tools.empty(props.label) ? props.label : "";
        this.states.photo = !Tools.empty(props) && !Tools.empty(props.photo) ? props.photo :null;
        this.states.alt = !Tools.empty(props) && !Tools.empty(props.alt) ? props.alt :"";
        this.states.nvdiscrpt = !Tools.empty(props) && !Tools.empty(props.nvdiscrpt) ? props.nvdiscrpt :"";
        this.states.width = !Tools.empty(props) && !Tools.empty(props.width) ? props.width :"250px";
        this.states.height = !Tools.empty(props) && !Tools.empty(props.height) ? props.height :"50px";
        
    }
    render() {
        this.initFromProps(this.props);
        let props = this.states;
        /*<img style={{width:props.width, height:props.height, 
            backgroundColor: props.backgroundColor}} src={props.photo} alt={props.alt} />
*/
        if (!Tools.empty(props.photo)){
            let src=props.photo;
        return (
            <div style={{ backgroundImage: `url(${src})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
            backgroundPosition: 'center',width:props.width,height:props.height}}>
        </div>
       );
        }else{
            return (
                <div style={{width:props.width, height:props.height, 
                    backgroundColor: props.backgroundColor}}  />
           );   
        }
    }
}

export default BaseImg;