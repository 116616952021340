import React, { Component } from 'react';import { Form, Button, Alert } from 'reactstrap';
import BaseInputString from './BaseInputString.jsx';
class BaseForm extends Component {
    constructor(props){
        super();
        this.props=props;
        this.objs={};
        let this2=this;
        setTimeout(()=>{
        this2.props.parent.form=this2;
        },240);
        this.state = {
          components: [],
        };
    }
    async valid(){
      for (let k in this.objs){
        let el=this.objs[k];
        let v= await el.valid();
      }
    }
    addObj(obj){
      this.objs[obj.states.name]=obj;
    }
    handleSubmit = event => {
      event.preventDefault();
      console.log("XXX");
      this.valid();
    }
    
  
  render() { 
    let error=null;   
    return (
      <Form onSubmit={this.handleSubmit}>
      {error && <Alert color="danger">{error}</Alert>}
      {this.props.children}
      <Button color="primary" type="submit">Login</Button>
    </Form>
    );
  }
}

export default BaseForm;