import React, { Component } from 'react';
import BaseSlimPage from '../../../spxbasecode/cmps/BaseSlimPage.jsx';
import GameFinish from '../cmp/GameFinish.jsx';


class MemtoePlayerLostPage extends Component {
  constructor() {
    super();
    let this2 = this;
    this.tag = "MemtoePlayerLostPage";
    this.module = "Memtoe";
    window.App.readText(this.module, this.tag, [{ tag: "text1" }]);

    setTimeout(function () {
      window.App.textToCommand.listen(this2, this2.tag);
    }, 500);
  }
  render() {
    return (
      <BaseSlimPage  isMenuShow={false}>      
      <GameFinish status="LOST"/>
      </BaseSlimPage>
    );
  }
}

export default MemtoePlayerLostPage;