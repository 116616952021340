import React from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import Tools from '../Tools.jsx';
const SpeechToText = (props) => {
    const { transcript, resetTranscript} = useSpeechRecognition();
    const handleListen = () => {
        resetTranscript();
        if (SpeechRecognition.browserSupportsSpeechRecognition) {
        SpeechRecognition.startListening({ continuous: true }).then(()=>{
          if (!SpeechRecognition.isMicrophoneAvailable) {
            //Tools.alert("No isMicrophoneAvailable");
          }
        });
    }else{
        Tools.alert("No speech recognation");
    }
    };
    const stopListening = () => {
        SpeechRecognition.stopListening();
    }
    const reset =()=>{
       resetTranscript();
    }
    
    const getTranscript=()=>{
        return transcript;
    }
    props.parent.speech = {};
    props.parent.speech.getTranscript = getTranscript;
    props.parent.speech.reset = reset;
    props.parent.speech.stopListening = stopListening;
    props.parent.speech.handleListen = handleListen;

    return (<div></div>);
};


export default SpeechToText;