import { Component } from 'react';
class Translate extends Component {
    static Trans = {
        pl_PL: {
            SSO:{
                SSOLoginForm:{
                    login:{
                        label:"Login",
                        placeholder:"Login"
                    },
                    passwd:{
                        label:"Hasło",
                        placeholder:"Hasło"
                    },
                    loginToSystem:{
                        label:"Zaloguj się"
                    },
                    register:{
                        label:"Zarejestruj się"
                    },
                }
            },
            Memtoe: {
                MemtoeHomePage: {
                    new_game: "Nowa gra",
                    change_lang: "Zmień język",
                    how_to_play: "Zasady gry",
                    about_us: "O nas"
                },
                NoSoundPage:{
                    no_sound:"Naciśnij przycisk aby przejść dalej"
                },MemtoeAboutUsPage: {
                    title: "O nas",
text: "Tworzymy wysokiej jakości aplikacje i gry edukacyjne dla osób niepełnosprawnych, zwłaszcza dla niewidomych. Naszym celem jest ułatwienie życia i poszerzenie wiedzy naszych użytkowników. Rozumiemy, że rozwój oprogramowania wymaga zasobów finansowych, dlatego potrzebujemy wsparcia, aby kontynuować tworzenie innowacyjnych rozwiązań. Jesteśmy wdzięczni za każdą pomoc i wsparcie finansowe, które otrzymujemy. Darczyńcy, którzy rozumieją znaczenie naszej misji, przyczyniają się do rozwoju naszych projektów i umożliwiają nam maksymalny wpływ na społeczność osób niepełnosprawnych. Poprzez wsparcie organizacji Edupanel.pl, darczyńcy stają się częścią naszej społeczności, która dąży do eliminowania barier i tworzenia bardziej włączającego świata dla osób niepełnosprawnych. Doceniamy każdą formę wsparcia, ponieważ pozwala nam kontynuować naszą misję i dostarczać wartościową edukację osobom niewidomym na całym świecie."
                },
                GameFinish:{
                    new_game:"Nowa Gra",
                    back:"Wstecz"
                },
                MemtoePlayerWinPage:{
                    title:"Gratlacje. Wygrałeś."
                },
                MemtoePlayerLostPage:{
                    title:"Niesty przegrałeś"
                },
                MemtoePlayerDrawPage:{
                    title:"Jest remis."
                },
                MemtoeHowToPlayPage: {
                    title: "Zasady gry",
text: "<ol><li>Gra rozpoczyna się na planszy o wymiarach 3x3.</li><li>Na początku gry plansza jest pusta.</li> <li>Gracze wykonują ruchy na przemian - najpierw gracz, a następnie przeciwnik AI.</li> <li>Gracz wybiera swoje pole poprzez wypowiedzenie numeru odpowiedniego pola (na przykład \"Wybierz pole numer 1\",\"Wybierz pole numer 2\" i tak dalej) lub kliknięcie na wybrane pole na ekranie.</li> <li>Po wybraniu pola, gracz stawia swój znak na planszy. Pole zostaje oznaczone jako zajęte.</li> <li>Jeśli gracz lub AI ułoży trzy swoje znaki w jednej linii - poziomej, pionowej lub ukośnej, gra kończy się, a zwycięzca zostaje  ogłoszony.</li> <li>Jeśli na planszy nie ma już dostępnych pól i nikt nie ułożył trzech znaków w jednej linii, gra kończy się remisem.</li> <li>Po zakończeniu gry, gracz może zdecydować, czy chce rozpocząć kolejną partię.</li></ol>"
                }
                
            }
        },
        en_GB:{
            SSO:{
            SSOLoginForm:{
                login:{
                    label:"Login",
                    placeholder:"Login"
                },
                passwd:{
                    label:"Password",
                    placeholder:"Password"
                },
                loginToSystem:{
                    label:"Log in"
                },
                register:{
                    label:"Register"
                },
            }
            },
            Memtoe: {
                MemtoeHomePage: {
                    new_game: "New Game",
                    how_to_play: "How to play",
                    about_us: "About us",
                    change_lang: "Change Lang",
                    login: "Login",
                    register:"Register",

                },
                GameFinish:{
                    new_game:"New Game",
                    back:"Back"
                },
                MemtoePlayerWinPage:{
                    title:"Player Win"
                },
                MemtoePlayerLostPage:{
                    title:"Player lost"
                },
                MemtoePlayerDrawPage:{
                    title:"Is draw"
                },
                MemtoeAboutUsPage: {
                    title: "About us",
text: "We create high-quality educational applications and games for people with disabilities, especially for the visually impaired. Our goal is to make life easier and expand the knowledge of our users. We understand that software development requires financial resources, which is why we need support to continue creating innovative solutions. We are grateful for any help and financial support we receive. Donors who understand the importance of our mission contribute to the development of our projects and enable us to have a maximum impact on the disabled community. By supporting the Edupanel.pl organization, donors become part of our community that strives to eliminate barriers and create a more inclusive world for people with disabilities. We appreciate every form of support as it allows us to continue our mission and provide valuable education to the visually impaired worldwide."
                },
                MemtoeHowToPlayPage: {
                    title: "How to play",
text: "<ol><li>The game starts on a 3x3 board.</li><li>At the beginning of the game, the board is empty.</li><li>Players take turns making moves - first the player, then the AI opponent.</li><li>The player chooses their square by stating the number of the corresponding square (for example, \"Choose square number 1\", \"Choose square number 2\" and so on) or by clicking on the selected square on the screen.</li><li>After selecting a square, the player places their mark on the board. The square is marked as occupied.</li><li>If a player or the AI manages to align three of their marks in a row - horizontally, vertically, or diagonally - the game ends, and the winner is announced.</li><li>If there are no more available squares on the board and no player has aligned three marks in a row, the game ends in a draw.</li><li>After the game ends, the player can decide whether they want to start another round.</li></ol>"
                },
                NoSoundPage:{
                    no_sound:"Press button to play sound"
                }
            }
        },
    };
    static getT(lang, module, page) {
        let TT = Translate.Trans[lang][module][page];
        return TT;
    }
}
export default Translate;