import React, { Component } from 'react';
import Translate from '../../../Translate.jsx';
import './../css/PlayerInfo.css';
import Tools from '../../../spxbasecode/Tools.jsx';

class PlayerInfo extends Component {
  constructor(props) {
    super();
    this.props = props;
    this.states = {};
    this.states.className = this.props.className;
    this.states.info = this.props.info;
    this.parent = props.parent;
    this.parent.info = this;
    this.awatar=props.awatar;
    
  }
  hide() {
    this.states.className = "hiddenComponent";
    this.forceUpdate();
  }
  show() {
    this.states.className = Tools.copy(this.parent.cssPlayer);
    this.forceUpdate();
  }
  getBackgroundColor() {

    return this.backgroundColor;
  }
  render() {
    let info={
      awatar:this.awatar,
      fullName:!Tools.empty(this.states.info)?this.states.info.fullName:"XXX"    
    };
    let awatar=!Tools.empty(info.awatar)?info.awatar:null;
    // let TT=Translate.getT(window.App.lang,"Memtoe","HomePage");
    
    return (
      <div className={this.states.className}>
        <div className="player-info">
          <img src={awatar} alt="Avatar" />
          <div className="player-details">
            <h3>{info.fullName}</h3>
          </div>
        </div>
      </div>
    );
  }
}

export default PlayerInfo;