import React, { Component } from 'react';
//import BaseButton from '../../../spxbasecode/cmps/BaseButton.jsx';
import Tools from '../../../spxbasecode/Tools.jsx';
import BaseButtonImage from '../../../spxbasecode/cmps/BaseButtonImage.jsx';
class Field extends Component {
  constructor(props) {
    super();
    this.props = props;
    this.nr = props.nr;
    this.parent = props.parent;
    this.isSelect = false;
    this.photo = null;
    this.player = null;
    this.backgroundColor = 'blue';
    this.parent.fields[props.nr - 1] = this;
    if (props.nr === "9") {
      this.parent.set_fields(this.parent.fields);
    }
  }
  isSelected() {
    return this.isSelect;
  }
  onSelect() {
    if (!this.isSelected()) {
      let r=Tools.GetCurrentTimeStamp()-window.lastSelectedFieldTime;
      if (r<=2200){
        return;
      }
      window.lastSelectedFieldTime=Tools.GetCurrentTimeStamp();
      this.isSelect = true;
      this.player = this.parent.currentPlayer;
      //this.backgroundColor=this.player.getBackgroundColor();
      this.photo = this.player.awatar;
      this.forceUpdate();
      let this2 = this;
      setTimeout(() => {
        this2.photo = null;
        this2.forceUpdate();
        this2.parent.pullFromNotSelectedFields(this);
        this2.parent.onSelectField(this);
      }, 2000);

    } else {
      return;
    }
  }
  click() {
    let xx = this.parent.currentPlayer;
    if (xx.typeClass === "Player") {

      this.onSelect();
      return;
    }
    
  }
  render() {
    return (
      <div className={this.props.className} style={{ backgroundColor: "green", padding: "5px 5px 5px 5px" }}>
        <BaseButtonImage label={this.props.nr} photo={this.photo} width="100%" height="100%" className="col-12" backgroundColor={this.backgroundColor} onClick={(event) => this.click()} style={{ height: "100%" }} />
      </div>
    );
  }
}

export default Field;