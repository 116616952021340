import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import Tools from '../Tools.jsx';
import BaseButtonImage from './BaseButtonImage.jsx';
import photo1 from '../img/soundOn.png'
class BaseReadTextButton extends Component {
  constructor(props) {
    super();
    this.props = props;
    this.states=props;
  }
  render() {
/*
 <div id="soundBt" style={{backgrandColor:"red",width:"50px",height:"50px"}} 
      onClick={(event) => {window.App.play();}}>
        </div>
*/
let photox=photo1;
    return (
      <div onClick={(event) => {window.App.play();}}>
     <BaseButtonImage width={this.states.width} onClick={(event) => {}}
     height={this.states.height} photo={photox}
     ></BaseButtonImage>
     </div>
    );
  }


}

export default BaseReadTextButton;