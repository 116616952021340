
import Tools from "./Tools";
class Validators {

    static async IsRequire(field,value){
        let req=Tools.empty(field.validator.require)?false:field.validator.require;
        if (!req){
            field.setOK();
            return true;
        }
        if (!Tools.empty(value)){
            field.setOK();
            return true;
        }
        field.setError("IsRequire");
        return false;
    }
}
export default Validators;