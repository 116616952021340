import React, { Component } from 'react';
import BaseSlimPage from '../../../spxbasecode/cmps/BaseSlimPage.jsx';
import BaseButton from '../../../spxbasecode/cmps/BaseButton.jsx';
import BaseImg from '../../../spxbasecode/cmps/BaseImg.jsx';
import Translate from '../../../Translate.jsx';
import img404 from '../../../img/404.png';
//import { AdMob } from 'admob';
class FundPage extends Component {
    constructor(){
    super();
      this.isAdsFinish=false;
      this.goNext=window.App.goNextPageInfo;
      this.adsID ="ca-app-pub-3940256099942544/2934735716";
  //  window.App.textToCommand.listen(this,"nopage");
  }
  goToPage(){

window.App.changePage(this.goNext.module,this.goNext.pageName,this.goNext.params);
  }
  back(){
    window.App.goBack();
  }
  render() {
    let this2=this;  
    setTimeout(()=>{

     this2.goToPage();
    },5000);  
  //    let TT=Translate.getT(window.App.lang,"Memtoe","HomePage");
  let TT2=Translate.getT(window.App.lang,"Memtoe","GameFinish");
   return (<div style={{width:"100%",height:"100%"}}>
    <a style={{height:"100%"}} className="row" href="https://www.buymeacoffee.com/edupanelpl" target="_blank">
    <div className='row'></div>
    <div className="col-sm-3 col-md-4"></div>
    <img className="h-10 col-xs-12 col-sm-6 col-md-4" src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" 
    alt="Buy Me A Coffee"  />
      </a><script 
      type="text/javascript" 
      src="https://cdnjs.buymeacoffee.com/1.0.0/button.prod.min.js" 
      data-name="bmc-button" 
      data-slug="edupanelpl" 
      data-color="#FFDD00" data-emoji="" data-font="Cookie" data-text="Buy me a coffee" 
      data-outline-color="#000000" 
      data-font-color="#000000" data-coffee-color="#ffffff" ></script>
   </div>);
   /* return (
      <div className='row h-100 g-0'>
      <div style={{height:"100%",width:"100%"}}>
      <AdMob
                    bannerSize="BannerAdSize.FULL_BANNER"
                    adUnitID={this.adsID}
                    servePersonalizedAds={false}
                />
      </div>
      </div>
    );*/
  }
}
/*

<a href=\"https://www.buymeacoffee.com/edupanelpl\" target=\"_blank\"><img src=\"https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png\" alt=\"Buy Me A Coffee\" style=\"height: 60px !important;width: 217px !important;\" ></a><script type=\"text/javascript\" src=\"https://cdnjs.buymeacoffee.com/1.0.0/button.prod.min.js\" data-name=\"bmc-button\" data-slug=\"edupanelpl\" data-color=\"#FFDD00\" data-emoji=\"\" data-font=\"Cookie\" data-text=\"Buy me a coffee\" data-outline-color=\"#000000\" data-font-color=\"#000000\" data-coffee-color=\"#ffffff\" ></script>

*/
export default FundPage;