import React, { Component } from 'react';
import Translate from '../../../Translate.jsx';
import Player from './Player.jsx';
import PlayerAI from './PlayerAI.jsx';
import Tools from '../../../spxbasecode/Tools.jsx';
import playerImgX from '../../../img/playerX.png';
import playerImg0 from '../../../img/player0.png';

class GameInfo extends Component {
  constructor(props) {
    super();
    window.lastSelectedFieldTime=Tools.GetCurrentTimeStamp()-3000;
    this.cssPlayer='col-12 h-100';
    this.props = props;
    this.parent = props.parent;
    this.players = [];
    this.parent.players =[];
    this.parent.gameInfo=this;
    this.currentId=null;
    let this2=this;
   /* 
   setTimeout(function(){
    if (!Tools.empty(this2.players[0])){
      this2.players[0].show();
      this2.players[1].hide();
    }
  },10);
  */
  }
  getBackgroundColor() {

    return this.backgroundColor;
  }
  setCurrentPlayer(player){
    this.currentPlayer=player;
    let n=this.players.length;
    for(let i=0;i<n;i++){
      if (this.players[i]===player){
        this.currentId=i;
        this.players[i].show();
      }else{
        this.players[i].hide();
      }
    }
    this.render();

  }
  setPlayers() {
    this.parent.setPlayers(this.players);
    
    
  }
  render() {
    let TT=Translate.getT(window.App.lang,"Memtoe","PlayerInfo");
    let showCss = "col-12 h-100";
    let hideCss = "d-none";
    let config={playerH:{
      color:"red",
      awatar:playerImgX
    },
    playerAI:{
      color:"yellow",
      awatar:playerImg0
    },};
    let css = [];
    css[0] = showCss;
    css[1] = hideCss;
    if (this.currentId==1){
      css[0]=hideCss;
      css[1] = showCss;
      
    }
    return (
      <div className={this.props.className}>
        <div className="row h-100">
          <Player className={css[0]} awatar={config.playerH.awatar} color={config.playerH.color} fullName={config.playerH.fullName} pos="0" parent={this} />
          <PlayerAI className={css[1]} awatar={config.playerAI.awatar} color={config.playerAI.color} fullName={config.playerAI.fullName} pos="1" parent={this} />
        </div>
      </div>
    );
  }
}

export default GameInfo;
