import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import Tools from '../Tools.jsx';
class BaseCard extends Component {
  constructor(props) {
    super();
    this.props = props;
  }
  render() {
    let backgroundColor=!Tools.empty(this.props.backgroundColor)?this.props.backgroundColor:"black";
    let color=!Tools.empty(this.props.color)?this.props.color:"white";
    let click=Tools.IsFunction(this.props.click)?this.props.click:function(ee){};
    return (
      <Card style={{backgroundColor:backgroundColor,color:color}} 
      onClick={(event) => click(event)}>
        <Card.Img style={{height:"25%"}} variant="top" src={this.props.src} />
        <Card.Body style={{backgroundColor:backgroundColor,color:color}}>
          <Card.Title style={{textAlign:"center"}}>{this.props.title}</Card.Title>
          <Card.Text  dangerouslySetInnerHTML={{ __html: this.props.text }}>
          
          </Card.Text>
        </Card.Body>
      </Card>
    );
  }


}

export default BaseCard;